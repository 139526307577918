<template>
  <header ref="tabs">
    <nav :class="{ '-sticky': sticky, '-stickyHeader': stickyHeader }">
      <div class="container" :class="{'text-center': item.declination === 'expertise'}">
        <ul v-if="item.declination === 'expertise'">
          <li v-for="(link, i) in items" :key="link.text">
            <a :href="'#timeline-section-' + tableIndex[i]" @click.prevent="scrollTo('#timeline-section-' + tableIndex[i])" :class="{'-active': tableIndex[i] === currentDecade}">{{ link.text }}</a>
          </li>
        </ul>
        <ul v-else>
          <li v-for="(link) in items" :key="link.text">
            <a :href="anchor ? anchor + link.anchor : '#timeline-section-' + link.anchor" @click.prevent="scrollTo(anchor ? anchor + link.anchor : '#timeline-section-' + link.anchor)" :class="{'-active': link.anchor === currentDecade}">{{ link.text }}</a>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import { ReadyHook } from 'vue-asyncready'

export default {
  name: 'TimelineToolbar',
  props: ['item', 'items', 'siblingsComponents', 'pagePosition', 'anchor'],
  mixins: [ReadyHook],
  created() {
    if (typeof HTMLElement !== 'undefined') {
      this.observer = new IntersectionObserver((entries) => {
        if (!entries[0].isIntersecting) {
          if (entries[0].boundingClientRect.y > entries[0].rootBounds.y) {
            this.currentDecade = this.tableIndex[this.tableIndex.findIndex(e => e === parseInt(entries[0].target.dataset.index)) - 1]
          }
        } else {
          this.currentDecade = parseInt(entries[0].target.dataset.index)
        }
      }, {
        threshold: 0.2,
        root: null,
        rootMargin: '0px 0px 0px 0px'
      })
    }
  },
  mounted() {
    window.addEventListener('scroll', this.headerPosition)
    this.tableIndex = this.siblingsComponents.map((component, index) => component.component === 'timeline_decade' ||
                                                                        component.component === 'podcast_expertise' ||
                                                                        component.component === 'generic_grid' ||
                                                                        component.component === 'category_block' ||
                                                                        component.component === 'movement_selector' ? index : null).filter(x => x !== null)
    setTimeout(() => {
      for (let i = 0; i < this.tableIndex.length; i++) {
        if (document.querySelector('#timeline-section-' + this.tableIndex[i])) {
          this.observer.observe(document.querySelector('#timeline-section-' + this.tableIndex[i]))
        } else if (this.anchor && document.querySelector(this.anchor + this.tableIndex[i])) {
          this.observer.observe(document.querySelector(this.anchor + this.tableIndex[i]))
        }
      }
    }, 100)
  },
  destroyed() {
    window.removeEventListener('scroll', this.headerPosition)
  },
  data() {
    return {
      sticky: false,
      stickyHeader: false,
      tableIndex: [],
      currentDecade: 1
    }
  },
  computed: {},
  methods: {
    headerPosition() {
      if (window) {
        let elemRect = this.$refs.tabs.getBoundingClientRect()
        console.log('scroll', elemRect.top)
        if (window.pageYOffset > elemRect.top + document.documentElement.scrollTop) {
          this.sticky = document.querySelector('#header-site').classList.contains('-fixed')
          this.stickyHeader = document.querySelector('#header-site').classList.contains('-show')
        } else {
          this.sticky = false
          this.stickyHeader = false
        }
      }
    },
    scrollTo(id) {
      if (window) {
        let bodyRect = document.body.getBoundingClientRect()
        let elemRect = document.querySelector(id).getBoundingClientRect()
        let offset = elemRect.top - bodyRect.top
        let decal = window.scrollY > offset ? 200 : 0
        window.scrollTo({
          top: offset - decal,
          behavior: 'smooth'
        })
      }
    },
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';

header {
  position: relative;
  height: 8.1rem;
  @include bp(max md) {
    height: 5.1rem;
  }
}

nav {
  position: absolute;
  z-index: 30;
  top: 0;
  width: 100%;
  background: var(--zenith-white);

  .container {
    @include bp(max md) {
      padding: 0;
    }
  }

  &.-sticky {
    position: fixed;
    transition: all 0.2s ease-in-out;
    transform: translateY(-8rem);

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: .1rem;
      border-bottom: 1px solid rgba(#979797, 0.3);
    }

    ul:before {
      display: none;
    }

    &.-stickyHeader {
      transform: translateY(12rem);
      @include bp(max md) {
        transform: translateY(9rem);
      }
    }
  }

  ul {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow-x: auto;
    white-space: nowrap;
    text-align: center;

    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
      border-radius: 2px;
      background-color: #DFDFDF;
    }

    &::-webkit-scrollbar-thumb {
      background: #262626;
      border-radius: 2px;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: .1rem;
      border-bottom: 1px solid rgba(#979797, 0.3);
    }

    @include bp(max md) {
      padding: 0 1.5rem;
      text-align: left;
    }

    li {
      padding: 0 3rem;
      display: inline-block;

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }

      a {
        display: block;
        padding: 3.4rem 0 0;
        height: 8rem;
        color: var(--zenith-grey-dark-2);
        font-weight: 500;
        font-size: em(12);
        line-height: em(18, 12);
        letter-spacing: em(1, 12);
        text-transform: uppercase;
        transition: all 0.2s ease-in-out;
        border-bottom: 2px solid transparent;
        outline: none;

        @include bp(max md) {
          padding-top: 1.8rem;
          height: 5rem;
        }

        &:hover, &.-active {
          border-bottom: 2px solid var(--zenith-black);
        }
      }
    }
  }
}
</style>
