<template>
  <section :id="'timeline-section-' + pagePosition" :class="'timeline-section bg-' + item.background" :data-index="pagePosition" ref="section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 col-md-6 order-1" :class="item.side === 'left' ? 'order-md-0' : 'order-md-1'">
          <div v-for="(content, i) in items" :key="content.title + '_' + i" :class="'content'">
            <Observer name="fade-up">
              <strong class="date" v-if="content.title">{{ content.title }}<img src="../assets/icons/font/star_4.svg" alt=""></strong>
              <h2 class="title" v-if="content.headline" v-html="content.headline"></h2>
              <p class="text" v-if="content.text" v-html="content.text"></p>
            </Observer>
          </div>
        </div>
        <div class="col-12 col-md-6 text-center order-0" :class="item.side === 'left' ? 'order-md-1' : 'order-md-0'">
          <Observer name="fade-up" @enter="enter">
            <parallax ref="parallax" :from="{ y: 100 }" :to="{ y: -100 }">
              <div slot-scope="{ parallax }" :style="`transform: translate3d(0px, ${ (parallax && parallax.y) ? Math.round(parallax.y) : 0 }px, 0px)`">
                <lazy-picture :item="item" preserve-ratio="true"></lazy-picture>
                <button type="button" class="btn-autoPlay -paused" @click="playVideo" ref="btn" v-if="item.type === 'video'"></button>
              </div>
            </parallax>
          </Observer>
        </div>
      </div>
    </div>
    <div ref="pop" class="player" :class="{'-show': played}" v-if="item.type === 'video'" :tabindex="played ? 0 : -1" v-bsl="played">
      <button type="button" ref="close" @click="pauseVideo" :aria-label="$t('Close')" class="close" :tabindex="played ? 0 : -1"><i class="icon-close"></i></button>
      <NoSSR>
        <Observer name="fade-up" @leave="leave">
          <vue-plyr ref="plyr" :options="plyrOption" :class="{ 'hide-controls': !hideInfo }">
            <video :src="(injected && item.video.url) ? item.video.url : ''" crossorigin="anonymous" playsinline>
              <track v-if="item.subtitle && item.subtitle.src && item.subtitle.srclang" kind="captions" :label="item.subtitle.label" :srclang="item.subtitle.srclang" :src="item.subtitle.src" default />
            </video>
          </vue-plyr>
        </Observer>
      </NoSSR>
    </div>
  </section>
</template>

<script>
import { ReadyHook } from 'vue-asyncready'
import { Parallax } from 'leaps/dist/leaps'
import LazyPicture from './utils/LazyPicture'
import NoSSR from 'src/themes/zenith/node_modules/vue-no-ssr'

if (process.browser) {
  var VuePlyr = require('vue-plyr/dist/vue-plyr.ssr.js')
}

export default {
  name: 'TimelineYear',
  props: ['item', 'items', 'pagePosition', 'siblings-components'],
  mixins: [ReadyHook],
  components: {
    LazyPicture,
    Parallax,
    VuePlyr,
    NoSSR
  },
  created () {},
  mounted () {
    document.addEventListener('keyup', this.keyup)
  },
  destroyed() {
    document.removeEventListener('keyup', this.keyup)
  },
  data () {
    return {
      played: false,
      injected: false,
      hideInfo: false,
      plyrOption: {
        controls: ['play', 'progress', 'current-time', 'mute', 'volume', 'settings', 'airplay', 'fullscreen', 'captions'],
        captions: {
          active: !!(this.item.subtitle && this.item.subtitle.srclang),
          language: (this.item.subtitle && this.item.subtitle.srclang) ? this.item.subtitle.srclang : 'en',
          fullscreen: {
            enabled: true,
            fallback: true,
            iosNative: true,
            container: null
          }
        }
      }
    }
  },
  methods: {
    keyup(e) {
      if (e.keyCode === 27 && this.item.type === 'video') {
        this.pauseVideo()
      }
    },
    playVideo () {
      if (this.$refs.plyr) {
        this.$refs.pop.style.display = 'flex'
        setTimeout(() => {
          this.played = true
          this.$refs.plyr.player.play()
          window.scrollTo({
            top: this.$el.getBoundingClientRect().top + window.scrollY
          })
          document.body.style.overflowY = 'hidden'
        }, 200)
      }
      this.hideInfo = true
    },
    pauseVideo () {
      if (this.$refs.plyr) {
        this.$refs.plyr.player.pause()
      }
      setTimeout(() => {
        this.played = false
        this.$refs.btn.focus()
      }, 200)
      setTimeout(() => {
        this.$refs.pop.style.display = 'none'
      }, 1000)
      document.body.style.overflowY = 'auto'
    },
    enter () {
      this.$refs.parallax.updateConfig()
      this.$refs.parallax.update()
      if (!this.injected) {
        this.injected = true
      }
    },
    leave () {
      if (this.$refs.plyr) {
        this.$refs.plyr.player.pause()
      }
    }
  },
  watch: {}
}
</script>

<style lang="scss">
section.timeline-section.bg-light + section.timeline-section.bg-light {
  padding-top: 0;
}
section.timeline-section.bg-dark + section.timeline-section.bg-dark {
  padding-top: 0;
}
</style>

<style lang="scss" scoped>
@import '~theme/css/core';

section {
  position: relative;
  padding: 14rem 0;

  @include bp(max md) {
    padding: 4rem 0;
  }

  &.bg {
    &-light {
      background: var(--zenith-white);
    }

    &-dark {
      background: var(--zenith-grey-light);
    }
  }

  /deep/ img {
    max-width: 90%;
    @include bp(max md) {
      margin-bottom: 4rem;
    }
  }

  /deep/ video {
    max-width: 100%;
    @include bp(max md) {
      margin-bottom: 4rem;
    }
  }

  .content {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 8rem;

      @include bp(max md) {
        margin-bottom: 3rem;
      }
    }
  }

  .date {
    position: absolute;
    left: 1rem;
    top: 0;
    width: 14rem;
    text-align: left;
    vertical-align: middle;
    font-weight: 400;
    font-size: em(84);
    line-height: em(60, 84);
    color: var(--zenith-grey-dark-2);
    display: flex;
    justify-content: space-between;

    @include bp(max md) {
      position: static;
      width: 100%;
      text-align: left;
      margin-bottom: 1.6rem;
      justify-content: flex-start;
    }

    img {
      display: inline-block;
      margin-left: 1.4rem;
    }
  }

  .title {
    color: var(--zenith-grey-dark-2);
    font-weight: 500;
    font-size: em(16);
    line-height: em(22, 16);
    text-transform: uppercase;
    margin-bottom: .8rem;
    margin-left: 18rem;

    @include bp(max md) {
      margin-left: 0;
    }
  }

  .text {
    color: var(--zenith-grey);
    font-weight: 400;
    font-size: em(16);
    line-height: em(28, 16);
    margin-bottom: 0;
    padding: 0;
    margin-left: 18rem;

    @include bp(max md) {
      margin-left: 0;
    }
  }

}

.btn-autoPlay {
  position: relative;
  right: 0;
  bottom: 0;
  float: right;
  border: 1px solid var(--zenith-black);
  box-shadow: none;

  &:before {
    border-left: 6px solid var(--zenith-black);
  }

  &:hover {
    background-color: var(--zenith-black);

    &:before {
      border-left: 6px solid var(--zenith-white);
    }
  }
}

.play {
  position: relative;
  width: 80px;
  height: 80px;
  font-size: em(16);
  border-radius: 50%;
  background-color: var(--zenith-black);
  color: var(--zenith-white);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
  margin-left: auto;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    border-left: 8px solid var(--zenith-white);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    transform: translate(-50%, -50%);
  }

  @include bp(max lg) {
    margin-top: 4rem;
  }

  &:hover {
    background-color: var(--zenith-white);
    color: var(--zenith-black);
  }
}

.close {
  position: absolute;
  z-index: 50;
  top: 2rem;
  right: 2rem;
  color: var(--zenith-white);
  background: none;
  border: none;
  font-size: em(30);

  @include bp(max md) {
    top: 1rem;
    right: 1rem;
    font-size: em(20);
  }

  i {
    display: block;
  }
}

.player {
  display: none;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 200;
  top: 0;
  opacity: 0;
  pointer-events: none;
  transition: all 0.6s ease-in-out;
  background: black;
  align-items: center;

  &.-show {
    opacity: 1;
    pointer-events: all;
  }

  /deep/ .obs {
    position: absolute;
    width: 100%;
  }

}

/deep/ .plyr {
  max-height: 100vh;

  &:not(.plyr__tab-focus) {
    .plyr__controls {
      opacity: 0;
    }

    &:hover {
      .plyr__controls {
        opacity: 1;
      }
    }
  }

  &--full-ui input[type='range'] {
    &.plyr__tab-focus {

      &::-webkit-slider-runnable-track {
        outline: 1px dashed var(--zenith-white);
      }

      &::-moz-range-track {
        outline: 1px dashed var(--zenith-white);
      }

      &::-ms-track {
        outline: 1px dashed var(--zenith-white);
      }
    }
  }

  &__controls {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);

    .plyr__tab-focus {
      outline: 1px dashed var(--zenith-white);
    }
  }

  &__poster {
    background-size: cover;
  }

  &__control--overlaid {
    background: var(--zenith-white);

    &:hover {
      svg {
        color: var(--zenith-white);
      }
    }

    svg {
      color: var(--zenith-black);
    }

  }
}

.hide-controls {
  /deep/ .plyr {
    .plyr__controls {
      opacity: 0 !important;
      pointer-events: none;
    }
  }
}

@include bp(max md) {
  /deep/ .obs {
    & > div {
      transform: none !important;
    }
  }
}
</style>
